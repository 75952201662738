import React from "react";
import axios from "axios";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";

// styles
import useStyles from "./styles";

// context
import { useUserDispatch, useUserState } from "../../context/UserContext";
import { useFleetContext } from "../../context/FleetContext";

const autoxLogo = "/images/logo.png";

function Login(props) {
    const classes = useStyles();
    // global
    const userState = useUserState();
    const userDispatch = useUserDispatch();

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code && !userState.codeUsed) {
        axios
            .post("https://sh-fms.autoxtaxi.com/admin/v1/slackAuth", {
                redirectUri: window.location.origin,
                code
            })
            .then(({ data }) => {
                if (data.user) {
                    window.localStorage.setItem("token", data.token);
                    window.localStorage.setItem("id_token", JSON.stringify(data));
                    userDispatch({ type: "LOGIN_SUCCESS", user: data });
                    return null;
                }
                console.log(data);
                userDispatch({ type: "LOGIN_FAILURE" });
            });
        return null;
    }

    return (
        <Grid container className={classes.container}>
            <div className={classes.logotypeContainer}>
                <img src={autoxLogo} alt="logo" className={classes.logotypeImage} />
            </div>
            <div className={classes.formContainer}>
                <div className={classes.form}>
                    <a
                        href={`https://slack.com/oauth/authorize?scope=identity.basic,identity.email,identity.team,identity.avatar&client_id=64756569494.1351112483189&redirect_uri=${window.location.origin}`}>
                        <img
                            alt="Sign in with Slack"
                            height="40"
                            width="172"
                            src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
                            srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
                        />
                    </a>
                </div>
            </div>
        </Grid>
    );
}

export default withRouter(Login);
